// @flow strict
import React, { useState, useEffect } from 'react';
import { withPrefix, Link } from 'gatsby';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import Form from '../../Form';
import { getContactHref } from '../../../utils';
import styles from './Author.module.scss';
import { useSiteMetadata } from '../../../hooks';

const Author = () => {
  const { author } = useSiteMetadata();
  const mixpanel = useMixpanel();

  return (
      <div className={styles['author']}>
      <Link to="/">
          <img
            src={withPrefix(author.photo)}
            className={styles['author__photo']}
            width="50"
            height="50"
            alt={author.name}
          />
        </Link>
        <p className={styles['author__bio']}>
          {author.bio}
        </p>
      </div>
    )

  // return (
  //   <div className={styles['author']}>
  //   <!--
  //     <p className={styles['author__bio']}>
  //       {author.bio}
  //       <a
  //         className={styles['author__bio-twitter']}
  //         href={getContactHref('twitter', author.contacts.twitter)}
  //         rel="noopener noreferrer"
  //         target="_blank"
  //       >
  //         <strong>{author.title}</strong> on Twitter
  //       </a>
  //     </p>
  //   </div>
  // );
};

export default Author;
